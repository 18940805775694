import { AppBarLayout, NavLogo, PlaystoreLogo } from "../../styles/navbar";
import parentIcon from "../../assets/parentingIcon.webp";
import playstore from "../../assets/playstore.jpeg";
import { parentingLink } from "../../constants/landing";
const Navbar = () => {
  return (
    <AppBarLayout>
      <NavLogo src={parentIcon} alt="parenting" />
      <a target="_blank" href={parentingLink}>
        <PlaystoreLogo src={playstore} />
      </a>
    </AppBarLayout>
  );
};

export default Navbar;
