import { Box, styled } from "@mui/material";

export const AppBarLayout = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "70px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  top: "0px",
  backgroundColor: "#FFF",
  zIndex: 999,
  color: theme?.palette?.primary?.main,
}));

export const NavLogo = styled("img")(({ theme }) => ({
  width: "40px",
  aspectRatio: 1,
  marginLeft: "22px",
  borderRadius: "24px",
  boxShadow:
    " 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)",
  objectFit: "contain",
  padding: "8px",
  background: "#311447",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    marginLeft: "8px",
  },
}));

export const PlaystoreLogo = styled("img")(({ theme }) => ({
  width: "110px",
  height: "50px",
  cursor: "pointer",
  borderRadius: "10px",
  objectFit: "contain",
  marginRight: "8px",
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "130px",
  },
}));
