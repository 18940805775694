import { ThemeProvider } from "@mui/material";
import React from "react";
import { theme } from "../styles/theme";
import Landing from "./pages/landing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "../routes";
import { routeMapping } from "../routes/routeMapping";
import { ROUTE_PATH } from "../routes/routePath";
import { ProtectedRoute } from "../routes/ProtectedRoute";

const Root = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={ROUTE_PATH.home} element={<ProtectedRoute />}>
            {routes?.map(({ path, element, children = null }, index) =>
              routeMapping(path, element, children, index)
            )}
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Root;
