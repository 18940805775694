import facebook from "../assets/social/facebook.png";
import instagram from "../assets/social/instagram.png";
import playStore from "../assets/social/playstore.png";

export const landingTitle = {
  header: "We're Still",
  main: "Cooking Our Website.",
  content: "We Are Going To Launch Our Website Very Soon.",
  footer: "Stay Tuned.",
  notify: "Notify Me ",
};

export const pageName = "Noble Parenting";

export const socialLinks = [
  { icon: facebook, link: "https://www.facebook.com" },
  { icon: instagram, link: "https://www.instagram.com" },
  {
    icon: playStore,
    link: "https://play.google.com/store/apps/details?id=com.bluetickcoders.nobleparenting",
  },
];

export const parentingLink =
  "https://play.google.com/store/apps/details?id=com.bluetickcoders.nobleparenting";
