import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { CustomLoader } from "../shared/CustomLoader";
import Navbar from "./Navbar";

export function Layout() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<CustomLoader />}>
        <Outlet />
      </Suspense>
    </>
  );
}
