import { Box, IconButton, styled } from "@mui/material";

export const TitleWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontSize: "20px",
  height: "100px",
  backgroundColor: "#E1E0F6",
  fontFamily: "Poppins, serif",
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    fontSize: "17px",
  },
}));

export const LandingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  minHeight: "calc(100svh - 100px)",
  [theme.breakpoints.down("sm")]: {
    minHeight: "calc(100svh - 60px)",
    gap: "12px",
  },
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  fontSize: "60px",
  fontWeight: "bold",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "30px",
  },
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  color: "#7E7D88",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export const NotifyButton = styled(Box)(({ theme }) => ({
  borderRadius: "32px",
  display: "flex",
  fontSize: "20px",
  gap: "25px",
  alignItems: "center",
  padding: "12px 20px 12px 12px",
  backgroundColor: "#171938",
  color: "#FFF",
  cursor: "pointer",
  boxSizing: "border-box",
  boxShadow:
    "rgba(207, 207, 219, 0.9) 0px 1px 0px, rgba(207, 207, 219, 0.9) 0px 8px 24px, rgba(207, 207, 219, 0.9) 0px 16px 48px",
}));

export const NotifyWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
}));

export const IconWrap = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme?.palette?.primary?.main,
  padding: "4px",
  color: "#FFF",
  "&:hover": {
    backgroundColor: theme?.palette?.primary?.main,
  },
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  borderBottomLeftRadius: "32px",
  borderBottomRightRadius: "32px",
  backgroundColor: "#FFF",
  padding: "12px",
  paddingInline: "32px",
  height: "max-content",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "24px",
  },
}));

export const SocialIconButton = styled(IconButton)(() => ({
  color: "white",
  marginTop: "10px",
}));

export const IconImage = styled(Box)(() => ({
  width: 24,
  height: 24,
}));

export const SocialWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  position: "absolute",
  bottom: "0px",
  height: "100px",
}));

export const SideImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "60px",
  },
}));

export const HeadView = styled(Box)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Poppins",
  color: "#7E7D88",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export const LogoContainer = styled("img")(({ theme }) => ({
  width: "66px",
  aspectRatio: 1,
  borderRadius: "24px",
  boxShadow:
    " 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)",
  [theme.breakpoints.down("sm")]: {
    width: "50px",
  },
  objectFit: "contain",
  padding: "8px",
  background: "#311447",
}));
