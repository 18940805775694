import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      // blue
      main: "#6255A4",
      dark: "#413B61",
      light: "#6254A6",
    },
    secondary: {
      // green
      main: "#89CC9F",
    },
    ternary: {
      // red
      main: "#FF5757",
    },
  },
});

// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#0F48D9",
//       light: "#3F72AF",
//     },
//     secondary: {
//       main: "#FF0000",
//     },
//   },
// });
